import React, { useState, useEffect } from "react"
import Service from "../Service/axios"

import Container from "../components/Container"
import ProjectList from "../components/ProjectList"
import Loader from "../components/Loader"

export default function Portfolio() {
    const [ projects, setProjects ] = useState([])

    const getProjects = async () => {
        const data = await Service.getData("projects")
        const sorted = data.sort((a, b) => (Number(a.position) > Number(b.position)) ? 1 : -1)
        setProjects(sorted)
    }

    useEffect(() => {
        getProjects()
    }, [])

    return (
        <Container>
            <article id="portfolio" className="wrapper">
                <h1>Portfolio</h1>

                {
                    projects.length === 0
                    ? <Loader />
                    : projects.map(x => <ProjectList key={x.id} project={x} />)
                }
            </article>
        </Container>
    )
}
import React from "react"
import { HiLocationMarker } from "react-icons/hi"

export default function EduList({ edu }) {
    return (
        <article className="list edu">
            <section className="left">
                <a href={edu.schoolLink}>
                    <p className="school">{edu.school}</p>
                </a>

                <p className="period">{edu.period}</p>
                
                <a href={edu.locationLink}>
                    <div className="location">
                        <HiLocationMarker />
                        <p>{edu.location}</p>
                    </div>
                </a>
            </section>
            
            <section className="right">
                <div className="title-container">
                    <p className="title">{edu.degree}</p>
                    <p className="specialization">{edu.specialization && `Specialized in ${edu.specialization}`}</p>
                </div>

                {edu.description.split(".").length > 1
                    ? edu.description.split(".").map((y, i) => (
                        <ul key={i} className="description">
                            <li>{y}</li>
                        </ul>
                    ))
                    : <p className="description">{edu.description}</p>
                }
            </section>
        </article>
    )
}

import React from "react"
import { BiLinkAlt } from "react-icons/bi"

export default function Achievement({ title, link, header, content, author }) {
    return (
        <section>
            <div className="header">
                <a href={link} target="_blank" rel="noreferrer">
                    <BiLinkAlt fontSize={28}/>  
                    <h3>{title}</h3>
                    {/* <p>hidden</p> */}
                </a>
            </div>

            <div className="content">
                <h4>"{header}</h4>
                <p>{content}"</p>
                <div className="author">
                    <div className="horizontal-line"></div>
                    <p>{author}</p>
                </div>
            </div>
        </section>
    )
}

import React from "react"

import ProjectLinkBar from "../ProjectLinkBar"

export default function ProjectList({ project }) {
    return (
        <article className="list project">
            <div className="portfolio-container">
                <section className="left">
                    <div className="overlay"></div>
                    <img src={project.imgLink} alt="Project Gif" width="350px" height="240px" />
                </section>
                
                <section className="right">
                    <div className="name">
                        <p>{project.name}</p>
                    </div>
                    <div className="techs">
                        {
                            project?.tech?.split(", ")?.map((t, i) => (
                                <p className="tech" key={i}>{t}</p>
                            ))
                        }
                    </div>

                    <p className="proj-description">{project.description}</p>

                    <section className="tags-bar-in">
                        <div className="tags">
                            {
                                project?.tags?.split(", ")?.map((t, i) => (
                                    <p className="tag" key={i}>{`#${t}`}</p>
                                ))
                            }
                        </div>

                        <ProjectLinkBar links={project.links} />
                    </section>
                </section>
            </div>
            

            <section className="tags-bar-out">
                <div className="tags">
                    {
                        project?.tags?.split(", ")?.map((t, i) => (
                            <p className="tag" key={i}>{`#${t}`}</p>
                        ))
                    }
                </div>

                <ProjectLinkBar links={project.links} />
            </section>
        </article>
    )
}

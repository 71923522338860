import React from "react"

const ContactButton = () => {
  return (
    <div className="contact-btn">
      <a href="mailto:vincentle.fh@gmail.com">Email me!</a>                    
    </div>
  )
}

export default ContactButton
import React from "react"
import { HiLocationMarker } from "react-icons/hi"
import { getDiffMonths } from "../../utils/helpers"
import { TiMediaRecordOutline } from "react-icons/ti"

export default function ExpList({ exp }) {
    return (
        <article className="list exp">
            <section className="left">
                <div className="period-container">
                    <p className="period"> {exp?.period}</p>
                    <TiMediaRecordOutline />
                    <p>{getDiffMonths(exp?.period?.split(" - ")[0], exp.period.split(" - ")[1])} mos</p>
                </div>
                
                <a href={exp?.locationLink}>
                    <div className="location">
                        <HiLocationMarker />
                        <p>{exp?.location}</p>
                    </div>
                </a>
            </section>
            
            <section className="right">
                <div className="title-container">
                    <p className="title">{exp?.title}</p>
                    <a href={exp?.companyLink}>
                        <p className="company"> @{exp?.company}</p>
                    </a>
                </div>

                {
                    exp?.description?.split(".")?.length > 1
                    ? exp?.description?.split(".")?.map((y, i) => (
                        <ul key={i} className="description">
                            <li>{y}</li>
                        </ul>
                    ))
                    : <p className="description">{exp?.description}</p>
                }
            </section>
        </article>
    )
}

import React, { useContext } from "react"

import { ThemeContext } from "../contexts/ThemeContext"
import { NavPanelContext } from "../contexts/NavPanelContext"
import Navbar from "../components/Navbar"
import Home from "./Home"
import About from "./About"
import Experience from "./Experience"
import Education from "./Education"
import Portfolio from "./Portfolio"
import Blogs from "./Blogs"
import Contact from "./Contact"
import Achievements from "./Achievements"
import Footer from "./Footer"

export default function Main() {
    const { theme } = useContext( ThemeContext )
    const { setShowPanel } = useContext( NavPanelContext )

    const handleClickOutsidePanel = (e) => {
        if ( e.target instanceof SVGElement ) return
        if ( e.target.alt && e.target.alt.includes("Theme Button") ) return

        if ( 
            !e.target?.className?.includes("nav-panel") &&
            !e.target?.className?.includes("hamburger") &&
            !e.target?.className?.includes("top") &&
            !e.target?.className?.includes("mid") &&
            !e.target?.className?.includes("bot")
        ) {
            setShowPanel(false) 
        }
    }

    return (
        <main
            className={theme === "light" ? "light app-container" : "dark app-container"} 
            onClick={handleClickOutsidePanel}
        >
            <Navbar />
            <Home />
            <About />
            <Experience />
            <Education />
            <Portfolio />
            <Achievements />
            <Blogs />
            <Contact />
            <Footer />
        </main>
    )
}

import React, { useContext } from "react"

import { NavPanelContext } from "../../contexts/NavPanelContext"
import ThemeToggler from "../ThemeToggler"
import NavLink from "./NavLink"
import SocialBar from "../SocialBar"
import DownloadBtn from "../DownloadBtn"

export default function NavPanel() {
    const { showPanel } = useContext( NavPanelContext )

    return (
        <aside className={ showPanel ? "nav-panel show" : "nav-panel" }>
            <section>
                <ThemeToggler />
            </section>

            <section>
                <NavLink name="Home" classN="nav-link" />
                <NavLink name="About" classN="nav-link" />
                <NavLink name="Experience" classN="nav-link" />
                <NavLink name="Education" classN="nav-link" />
                <NavLink name="Portfolio" classN="nav-link" />
                <NavLink name="Achievements" classN="nav-link" />
                <NavLink name="Blogs" classN="nav-link" />
                <NavLink name="Contact" classN="nav-link" />
            </section>

            <section>
                <DownloadBtn />
            </section>

            <section>
                <SocialBar />
            </section>
        </aside>
    )
}

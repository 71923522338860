import React, { useState, useContext, useEffect } from "react"

import { NavPanelContext } from "../../contexts/NavPanelContext"
import ThemeToggler from "../ThemeToggler"

export default function Hamburger() {
    const { showPanel, setShowPanel } = useContext( NavPanelContext )
    const [ fade, setFade ] = useState(false)
    const [ turn, setTurn ] = useState(false)

    useEffect(() => {
        if ( showPanel === true ) {
            setTurn(true)
            setFade(true)
        } else {
            setTurn(false)
            setFade(false)
        }
    }, [showPanel])

    const transition = () => {
        setTurn(!turn)
        setFade(!fade)
        setShowPanel(!showPanel)
    }

    return (
        <div className="hamburger-container">
            <ThemeToggler />
            
            <div className="hamburger" onClick={transition}>
                <div className={ turn ? "top turn" : "top" }></div>
                <div className={ fade ? "mid fade" : "mid" }></div>
                <div className={ fade ? "bot fade" : "bot" }></div>
            </div>
        </div>
    )
}

import React from "react"

export default function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="first"></div>
                <div className="second"></div>
                <div className="third"></div>
            </div>
        </div>
    )
}

import React from "react"

import { 
    SiHtml5, 
    SiCss3,
    SiJavascript,
    SiTypescript,
    SiNextdotjs,
    SiReact,
    SiPython,
    SiSass,
    SiTailwindcss,
    SiBootstrap,
    SiGit,
    SiExpress,
    SiFirebase,
    SiPostgresql,
    SiAmazonaws,
    SiMicrosoftazure,
    SiGnubash,
    SiMongodb,
    SiGraphql,
    SiCypress,
    SiJest,
    SiAdobeillustrator,
    SiAdobexd,
    SiFigma,
    SiBlender,
} from "react-icons/si"
import { IoLogoNodejs } from "react-icons/io"
import { FaPhp } from "react-icons/fa"
import { GrMysql } from "react-icons/gr"
import { RiReactjsLine, RiVuejsFill } from "react-icons/ri"

export default function SkillItem({ name, classN, link }) {
    const getIcon = () => {
        switch (classN) {
            case "html":
                return <SiHtml5 />
            case "css":
                return <SiCss3 />
            case "js":
                return <SiJavascript />
            case "ts":
                return <SiTypescript />
            case "next":
                return <SiNextdotjs />
            case "react":
                return <SiReact />
            case "vue":
                return <RiVuejsFill />
            case "react-native":
                return <RiReactjsLine />
            case "python":
                return <SiPython />
            case "php":
                return <FaPhp />
            case "sass":
                return <SiSass />
            case "tailwind":
                return <SiTailwindcss />
            case "bootstrap":
                return <SiBootstrap />
            case "git":
                return <SiGit />
            case "firebase":
                return <SiFirebase />
            case "aws":
                return <SiAmazonaws />
            case "azure":
                return <SiMicrosoftazure />
            case "bash":
                return <SiGnubash />
            case "mongo":
                return <SiMongodb />
            case "node":
                return <IoLogoNodejs />
            case "express":
                return <SiExpress />
            case "graphql":
                return <SiGraphql />
            case "postgres":
                return <SiPostgresql />
            case "mysql":
                return <GrMysql />
            case "jest":
                return <SiJest />
            case "cypress":
                return <SiCypress />
            case "ai":
                return <SiAdobeillustrator />
            case "xd":
                return <SiAdobexd />
            case "figma":
                return <SiFigma />
            case "blender":
                return <SiBlender />
            default: 
                return null
        }
    }

    return (
        <a href={link} target="_blank" rel="noreferrer">
        <div className={`skill ${classN}`}>
            {getIcon()}
            <p>{name}</p>
        </div>
        </a>
    )
}

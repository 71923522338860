import Container from "../components/Container"

const Blogs = () => {
  return (
    <Container>
        <article id="blogs" className="wrapper">
          <h1>Blogs</h1>

          <section>
            <a href="https://vincentlev.github.io/" target="_blank" rel="noreferrer">
              <iframe 
                width="500" 
                height="230" 
                src="https://vincentlev.github.io/" 
                title="Personal Blog On Software Development" 
                frameborder="0" 
                scrolling="no"
                seamless="seamless"
              ></iframe>
            </a>

            <a href="https://datasecurity-vincentle.netlify.app/" target="_blank" rel="noreferrer">
              <iframe 
                width="500" 
                height="230" 
                src="https://datasecurity-vincentle.netlify.app/" 
                title="Data Security Portfolio" 
                frameborder="0" 
                scrolling="no"
                seamless="seamless"
              ></iframe>
            </a>
          </section>
        </article>
    </Container>
  )
}

export default Blogs
import React from "react"

import SkillItem from "../SkillItem"

export default function Skills() {
    return (
        <section className="skills">          
            <section>
                <SkillItem name="NEXTJS" classN="next" link="https://nextjs.org/docs" />
                <SkillItem name="REACTJS" classN="react" link="https://reactjs.org/docs/getting-started.html" />
                <SkillItem name="VUEJS" classN="vue" link="https://vuejs.org/guide/introduction.html" />
                <SkillItem name="REACT NATIVE" classN="react-native" link="https://reactnative.dev/docs/getting-started" />
                <SkillItem name="JAVASCRIPT" classN="js" link="https://javascript.info/" />
                <SkillItem name="TYPESCRIPT" classN="ts" link="https://www.typescriptlang.org/docs/" />
                <SkillItem name="PYTHON" classN="python" link="https://www.python.org/doc/" />
                <SkillItem name="PHP" classN="php" link="https://www.php.net/" />
                <SkillItem name="HTML5" classN="html" link="https://html.com/" />

                <SkillItem name="CSS3" classN="css" link="https://www.tutorialrepublic.com/css-tutorial/" />
                <SkillItem name="SASS" classN="sass" link="https://sass-lang.com/documentation/" />
                <SkillItem name="TAILWIND" classN="tailwind" link="https://tailwindcss.com/docs/installation" />
                <SkillItem name="BOOTSTRAP" classN="bootstrap" link="https://getbootstrap.com/docs/5.1/getting-started/introduction/" />

                <SkillItem name="NODEJS" classN="node" link="https://nodejs.org/en/docs/" />
                <SkillItem name="EXPRESSJS" classN="express" link="https://expressjs.com/en/starter/installing.html" />
                <SkillItem name="GRAPHQL" classN="graphql" link="https://graphql.org/learn/" />
                <SkillItem name="MONGODB" classN="mongo" link="https://www.mongodb.com/" />
                <SkillItem name="FIREBASE" classN="firebase" link="https://firebase.google.com/docs" />
                <SkillItem name="POSTGRESQL" classN="postgres" link="https://www.postgresql.org/docs/" />
                <SkillItem name="MYSQL" classN="mysql" link="https://dev.mysql.com/doc/" />
                <SkillItem name="AWS" classN="aws" link="https://docs.aws.amazon.com/index.html?nc2=h_ql_doc_do_v" />
                <SkillItem name="AZURE" classN="azure" link="https://docs.microsoft.com/en-us/azure/?product=popular" />
            
                <SkillItem name="JEST" classN="jest" link="https://jestjs.io/docs/getting-started" />
                <SkillItem name="CYPRESS" classN="cypress" link="https://docs.cypress.io/guides/overview/why-cypress" />
                <SkillItem name="GIT" classN="git" link="https://git-scm.com/doc" />
                <SkillItem name="BASH SCRIPT" classN="bash" link="https://linuxconfig.org/bash-scripting-tutorial" />
            </section>

            <section>
                
            </section>

            <section>
                <SkillItem name="ADOBE ILLUSTRATOR" classN="ai" link="https://www.adobe.com/products/illustrator.html" />
                <SkillItem name="ADOBEXD" classN="xd" link="https://www.adobe.com/products/xd.html" />
                <SkillItem name="FIGMA" classN="figma" link="https://www.figma.com/" />
                <SkillItem name="BLENDER" classN="blender" link="https://www.blender.org/" />
            </section>
        </section>
    )
}

import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"

export default function Cursor() {
    const [ position, setPosition ] = useState({x: 0, y: 0})
    const [ hidden, setHidden ] = useState(false)
    const [ clicked, setClicked ] = useState(false)
    const [ hovered, setHovered ] = useState(false)
    const [ aHovered, setAHovered ] = useState(false)
    const [ imgHovered, setImgHovered ] = useState(false)
    const [ skillHovered, setSkillHovered ] = useState(false)
    const [ iframeHovered, setIframeHovered ] = useState(false)
    
    useEffect(() => {
        addEventListeners()
        handleHoverEvents()
        return () => removeEventListeners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const addEventListeners = () => {
        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mouseenter", onMouseEnter)
        document.addEventListener("mouseleave", onMouseLeave)
        document.addEventListener("mousedown", onMouseDown)
        document.addEventListener("mouseup", onMouseUp)
    }
    
    const removeEventListeners = () => {
        document.removeEventListener("mousemove", onMouseMove)
        document.removeEventListener("mouseenter", onMouseEnter)
        document.removeEventListener("mouseleave", onMouseLeave)
        document.removeEventListener("mousedown", onMouseDown)
        document.removeEventListener("mouseup", onMouseUp)
        document.removeEventListener("mouseover", () => {
            setAHovered(true)
            setHovered(true)
            setImgHovered(true)
            setIframeHovered(true)
        })
        document.removeEventListener("mouseout", () => {
            setAHovered(false)
            setHovered(false)
            setImgHovered(false)
            setIframeHovered(false)
        })
    }

    const handleHoverEvents = () => {
        document.querySelectorAll("a").forEach((el) => {
            el.addEventListener("mouseover", () => setAHovered(true))
            el.addEventListener("mouseout", () => setAHovered(false))
        })

        document.querySelectorAll("h1").forEach((el) => {
            el.addEventListener("mouseover", () => setHovered(true))
            el.addEventListener("mouseout", () => setHovered(false))
        })

        document.querySelectorAll("img").forEach((el) => {
            el.addEventListener("mouseover", () => setImgHovered(true))
            el.addEventListener("mouseout", () => setImgHovered(false))
        })

        document.querySelectorAll("iframe").forEach((el) => {
            el.addEventListener("mouseover", () => setIframeHovered(true))
            el.addEventListener("mouseout", () => setIframeHovered(false))
        })

        document.querySelector(".skills").addEventListener("mouseover", () => setSkillHovered(true))
        document.querySelector(".skills").addEventListener("mouseout", () => setSkillHovered(false))
    }

    const onMouseLeave = () => setHidden(true)

    const onMouseEnter = () => setHidden(false)
    
    const onMouseMove = (e) => setPosition({x: e.clientX, y: e.clientY})
    
    const onMouseDown = () => setClicked(true)

    const onMouseUp = () => setClicked(false)

    const CursorContainer = () => {
        if ( hidden ) {
            return <div className="cursor hide" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( clicked ) {
            return <div className="cursor clicked" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( hovered ) {
            return <div className="cursor hovered" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( aHovered ) {
            return <div className="cursor a-hovered" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( imgHovered ) {
            return <div className="cursor img-hovered" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( skillHovered ) {
            return <div className="cursor skill-hovered" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        } else if ( iframeHovered ) {
            return <div className="iframe-hovered" />
        } else {
            return <div className="cursor" style={{ left: `${position.x}px`,top: `${position.y}px` }} />
        }
    }

    if ( isMobile ) return null
    return <CursorContainer />
}

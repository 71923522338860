import React, { useEffect } from "react"
import "./styles/styles.css"

import ThemeProvider from "./contexts/ThemeContext"
import NavPanelProvider from "./contexts/NavPanelContext"
import Main from "./pages/Main"

function App() {
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const navList = document.querySelector(".nav-list")

            if ( window.scrollY >= 140  ) {
                navList.classList.add("hide")
            } else {
                navList.classList.remove("hide")
            }
        })
    }, [])
    
    return (
        <ThemeProvider>
            <NavPanelProvider>
                <Main />
            </NavPanelProvider>
        </ThemeProvider>
    );
}

export default App

import React from "react"
import { isMobile } from "react-device-detect"

import myCV from "../../assets/VincentLe_CV_DataEngineer.pdf"
import { HiOutlineDownload } from "react-icons/hi"


export default function DownloadBtn() {
    const cvLink = `https://github.com/VincentLeV/cv-developer/blob/main/VincentLe_CV_DataEngineer.pdf?raw=true`

    return (
        <div className="download-btn">
            <a href={ isMobile ? myCV : cvLink } download>
                <div>
                    <HiOutlineDownload size="25px"/>
                    <p>Resume</p>
                </div>
            </a>
        </div>
    )
}

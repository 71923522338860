import React, { useContext } from "react"

import { ThemeContext } from "../../contexts/ThemeContext"
import sigBlack from "../../assets/sig-black.svg"
import sigWhite from "../../assets/sig-white.svg"

export default function PageLogo() {
    const { theme } = useContext( ThemeContext )

    return (
        <div className="page-logo">
            <a href="#about">
                <img 
                    width="70px"
                    height="35px"
                    src={theme === "light" ? sigBlack : sigWhite} 
                    alt="Page Logo Dark Version" 
                />
                {/* <p>hidden</p> */}
            </a>
        </div>
    )
}

import React from "react"

import SocialBtn from "./SocialBtn"

export default function SocialBar() {
    return (
        <div className="social-bar">
            <SocialBtn name="mail" href="mailto:vincentle.fh@gmail.com" />
            <SocialBtn name="linkedin" href="https://www.linkedin.com/in/vincentlefh/" />
            <SocialBtn name="github" href="https://github.com/VincentLeV" />
            <SocialBtn name="blog" href="https://vincentlev.github.io/" />
            {/* <SocialBtn name="cw" href="https://www.codewars.com/users/VincentLeV" /> */}
        </div>
    )
}

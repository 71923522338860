export const getDiffMonths = ( date1, date2 ) => {
    const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
    let month1
    let month2

    const present = new Date()
    const currentMonth = present.getUTCMonth() + 1
    const currentYear = present.getUTCFullYear()

    let monthBefore = date1.split(" ")[0]
    let yearBefore = date1.split(" ")[1]
    let monthAfter = date2.split(" ")[0]
    let yearAfter = date2.split(" ")[1]

    months.forEach( (item, index) => {
        if (item === monthBefore) {
            month1 = index + 1
        }
        if ( item === monthAfter ) {
            month2 = index + 1
        }
    })
    if ( monthAfter === "Present") {
        month2 = currentMonth
    }
    if ( yearAfter === undefined ) {
        yearAfter = currentYear
    }
    const dateObj1 = new Date( `${month1}/1/${yearBefore}` )
    const dateObj2 = new Date( `${month2}/1/${yearAfter}` )
    const diff = (dateObj2.getTime() - dateObj1.getTime()) / 1000
    const diffMonths = Math.abs( Math.ceil(diff / (60 * 60 * 24 * 7 * 4 )) )
    // console.log("1",dateObj1)
    // console.log("2", dateObj2)
    return diffMonths
}
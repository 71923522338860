import React from "react"

import PageLogo from "../PageLogo"
import Hamburger from "../Hamburger"
import NavPanel from "./NavPanel"
import NavList from "./NavList"

export default function Navbar() {
    return (
        <header>
            <nav>
                <div className="navbar-wrapper">
                    <PageLogo />
                    <NavList />
                    <Hamburger />
                    <NavPanel />
                </div>
            </nav>
        </header>
    )
}

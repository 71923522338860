import React, { useState, useEffect } from "react"
import Service from "../Service/axios"

import Container from "../components/Container"
import EduList from "../components/EduList"
import Loader from "../components/Loader"

export default function Education() {
    const [ edu, setEdu ] = useState([])


    const getEdu = async () => {
        const data = await Service.getData("education")
        setEdu(data)
    }

    useEffect(() => {
        getEdu()
    }, [])

    return (
        <Container>
            <article id="education" className="wrapper">
                <h1>Education</h1>

                {
                    edu.length === 0
                    ? <Loader />
                    : <div className="edu-container">
                        {edu.map(x => <EduList key={x.id} edu={x} />)}
                    </div>
                }
            </article>
        </Container>
    )
}
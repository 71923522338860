import React from "react"

import { 
    SiLinkedin,
    SiGithub,
    SiCodewars
} from "react-icons/si"
import { ImMail4, ImPen } from "react-icons/im"

export default function SocialBtn({ name, href }) {
    let icon = null

    switch (name) {
        case "linkedin":
            icon = <SiLinkedin color="#5cdb95" />
            break
        case "github":
            icon = <SiGithub color="#5cdb95" />
            break
        case "cw":
            icon = <SiCodewars color="#5cdb95" />
            break
        case "mail":
            icon = <ImMail4 color="#5cdb95" />
            break
        case "blog":
            icon = <ImPen color="#5cdb95" />
            break
        default:
            return null 
    }

    return (
        <a href={href} target="_blank" rel="noreferrer" aria-label={name} role="button">
            {icon}
            {/* <p>hidden</p> */}
        </a>
    )
}

import React from "react"

import Container from "../components/Container"
import Skills from "../components/Skills"

export default function About() {
    return (
        <Container>
            <article id="about" className="wrapper">
                <h1>About</h1>
                
                <article className="intro">
                    <p>I'm a Data Engineer &amp; Fullstack Developer with the knowledge of UX/UI Design.</p>
                    <p>Ambitious, artistic, hard-working.</p>
                    <p>Painting, drawing using mixed media. Playing piano and guitar. Sing in the shower. Coding, coding and coding...</p>
                    <p>Interested in being a part of meaningful projects.</p>
                    <p>Machine Learning and IoT Enthusiast.</p>
                </article>

                <Skills />
            </article>
        </Container>
    )
}

import React, { useState, createContext } from "react"

export const NavPanelContext = createContext()

export default function NavPanelProvider({ children }) {
    const [ showPanel, setShowPanel ] = useState( false )

    return (
        <NavPanelContext.Provider value={{ showPanel, setShowPanel }} >
            { children }
        </NavPanelContext.Provider>
    )
}

import React, { useContext } from "react"

import { NavPanelContext } from "../../contexts/NavPanelContext"

export default function NavLink({ name, classN }) {
    const { setShowPanel } = useContext( NavPanelContext )

    return (
        <a href={`#${name.toLowerCase()}`} onClick={() => setShowPanel(false)}>
            <p className={classN}>{name}</p>
        </a>
    )
}

import React from "react"

import Container from "../components/Container"
import Quote from "../components/Quote"
import SocialBar from "../components/SocialBar"
import SocialBtn from "../components/SocialBar/SocialBtn"
import myPhoto from "../assets/main-photo.jpg"
import DownloadBtn from "../components/DownloadBtn"
import ContactButton from "../components/ContactButton"

export default function Home() {
    return (
        <Container>
            <article id="home" className="wrapper">
                <section>
                    <h1>Hi,</h1>
                    <h1> I'm Vincent Le.</h1>
                    <div className="title-container">
                        <p className="title">Data Engineer</p>
                        <p className="title">Full-stack Developer</p>
                    </div>
                </section>

                <Quote />

                <section>
                    <DownloadBtn />
                    <ContactButton />

                    {/* <a href="https://www.codewars.com/users/VincentLeV" target="_blank" rel="noreferrer">
                        <div className="cw-badge">
                            <SocialBtn name="cw" href="https://www.codewars.com/users/VincentLeV" />
                            <img
                                src="https://www.codewars.com/users/VincentLeV/badges/micro" 
                                alt="Codewars Badge" 
                            />
                        </div>
                        {/* <p>hidden</p> */}
                    {/* </a> */}
                </section>

                <section>
                    <SocialBar />
                        <div className="cw-badge">
                            <SocialBtn name="cw" href="https://www.codewars.com/users/VincentLeV" />
                            <a href="https://www.codewars.com/users/VincentLeV" target="_blank" rel="noreferrer">
                                <img
                                    src="https://www.codewars.com/users/VincentLeV/badges/micro" 
                                    alt="Codewars Badge" 
                                />
                                {/* <p>hidden</p> */}
                            </a>
                        </div>
                </section>

                <img src={myPhoto} alt="My Img" width="360px" className="my-photo" />
            </article>
        </Container>
    )
}

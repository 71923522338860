import React, { useContext } from "react"

import { ThemeContext } from "../../contexts/ThemeContext"
import light from "../../assets/light.svg"
import dark from "../../assets/dark.svg"


export default function ThemeToggler() {
    const { theme, setTheme } = useContext( ThemeContext )

    const changeTheme = () => theme === "light" ? setTheme("dark") : setTheme("light")

    return (
        <div className="theme-toggler" onClick={changeTheme}>
            <img src={ theme === "light" ? light : dark } alt="Theme Button" />
        </div>
    )
}

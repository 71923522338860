import React from "react"

import NavLink from "./NavLink"

export default function NavList() {
    return (
        <section className="nav-list">
            <NavLink name="Home" classN="list-link" />
            <NavLink name="About" classN="list-link" />
            <NavLink name="Experience" classN="list-link" />
            <NavLink name="Education" classN="list-link" />
            <NavLink name="Portfolio" classN="list-link" />
            <NavLink name="Achievements" classN="list-link" />
            <NavLink name="Contact" classN="list-link" />
        </section>
    )
}

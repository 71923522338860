import React, { useState, useEffect } from "react"
import Service from "../Service/axios"

import Container from "../components/Container"
import ExpList from "../components/ExpList"
import Loader from "../components/Loader"

export default function Experience() {
    const [ exp, setExp ] = useState([])

    const getExp = async () => {
        const data = await Service.getData("experience")
        const sorted = data.sort((a, b) => (Number(a.position) > Number(b.position)) ? 1 : -1)
        setExp(sorted)
    }

    useEffect(() => {
        getExp()
    }, [])

    return (
        <Container>
            <article id="experience" className="wrapper">
                <h1>Experience</h1>

                { exp.length === 0
                    ? <Loader /> 
                    : <div className="exp-container">
                        {exp?.map(x => <ExpList key={x.id} exp={x} />)}
                    </div>
                }
            </article>
        </Container>
    )
}

import axios from "axios"
const baseUrl = `${process.env.REACT_APP_API_URL}/api`

const getData = async (type) => {
    const res = await axios.get(`${baseUrl}/${type}`)
    return res.data
}

const Service = {
    getData
}

export default Service
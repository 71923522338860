import React from "react"

import usa from "../../assets/usa.svg"
import vn from "../../assets/vietnam.svg"
import fin from "../../assets/finland.svg"

export default function Quote() {
    return (
        <section className="quote">
            <div>
                <span><em>Doing business with me in</em></span>
                <a href="/" target="_blank" rel="noreferrer">
                    <img src={usa} alt="US Flag" width="14px" height="14px"/>
                    <span><sup><strong>EN</strong></sup></span>
                </a>

                <a href="https://www.britannica.com/place/Vietnam" target="_blank" rel="noreferrer">
                    <img src={vn} alt="VN Flag" width="16px" height="16px"/>
                    <span><sup><strong>VN</strong></sup></span>
                </a>
            </div>

            <div>
                <span><em>Emailing me in</em></span>
                <a href="/" target="_blank" rel="noreferrer">
                    <img src={usa} alt="US Flag" width="14px" height="14px"/>
                    <span><sup><strong>EN</strong></sup></span>
                </a>

                <a href="https://www.britannica.com/place/Vietnam" target="_blank" rel="noreferrer">
                    <img src={vn} alt="VN Flag" width="16px" height="16px"/>
                    <span><sup><strong>VN</strong></sup></span>
                </a>

                <a href="https://www.britannica.com/place/Finland" target="_blank" rel="noreferrer">
                    <img src={fin} alt="FIN Flag" width="16px" height="16px"/>
                    <span><sup><strong>FI</strong></sup></span>
                </a>
            </div>
            
            <div>
                <span><em>Chitchatting with me in</em></span>
                <a href="/" target="_blank" rel="noreferrer">
                    <img src={usa} alt="US Flag" width="14px" height="14px"/>
                    <span><sup><strong>EN</strong></sup></span>
                </a>

                <a href="https://www.britannica.com/place/Vietnam" target="_blank" rel="noreferrer">
                    <img src={vn} alt="VN Flag" width="16px" height="16px"/>
                    <span><sup><strong>VN</strong></sup></span>
                </a>

                <a href="https://www.britannica.com/place/Finland" target="_blank" rel="noreferrer">
                    <img src={fin} alt="FIN Flag" width="16px" height="16px"/>
                    <span><sup><strong>FI</strong></sup></span>
                </a>
            </div>
        </section>
    )
}

import React from "react"
import { SiGithub } from "react-icons/si"
import { BiLinkAlt } from "react-icons/bi"
import { ImBlog } from "react-icons/im"

export default function ProjectLinkBar({ links }) {
    const getIcon = (name) => {
        switch (name) {
            case "Github":
                return <SiGithub />
            case "Demo":
                return <BiLinkAlt />
            case "Blog":
                return <ImBlog />
            default:
                return null
        }
    }

    return (
        <div className="proj-links">
            {
                links?.map((l, i) => (
                    <a key={i} href={l.link} target="_blank" rel="noreferrer">
                        <div className="proj-link">{getIcon(l.name)}</div>
                    </a>
                ))
            }
        </div>
    )
}

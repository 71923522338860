import React, { useState, useEffect } from "react"
import Service from "../Service/axios"

import Container from "../components/Container"
import Achievement from "../components/Achievement"
import Loader from "../components/Loader"
import awsBadge from "../assets/aws.png"
import hhBadge from "../assets/hh.png"
import az900Badge from "../assets/az900.png"
import dp900Badge from "../assets/dp900.png"
import aiKnowledgeMiningBadge from "../assets/aiknowledgemining.png"

export default function Achievements() {
    const [ achievements, setAchievements ] = useState([])

    const getAchievements = async () => {
        const data = await Service.getData("achievements")
        setAchievements(data)
    }

    useEffect(() => {
        getAchievements()
    }, [])

    return (
        <Container>
            <article id="achievements" className="wrapper">
                <h1>Achievements</h1>

                <section className="badges first-row">
                    <a href="https://www.credly.com/badges/cc8d977e-9714-47ca-8d95-71bf6d78f0db/public_url" target="_blank" rel="noreferrer">
                        <img src={dp900Badge} alt="DP900 Badge" width="100px" height="100px" />
                        {/* <p>hidden</p> */}
                    </a>
                    <a href="https://www.credly.com/badges/ae32c35b-72ea-4113-bdcc-cb219be9773a" target="_blank" rel="noreferrer">
                        <img src={az900Badge} alt="AZ900 Badge" width="100px" height="100px" />
                        {/* <p>hidden</p> */}
                    </a>
                    <a href="https://www.credly.com/badges/4151855b-3fbd-4340-ac9b-d0fbff8e9404/public_url" target="_blank" rel="noreferrer">
                        <img src={aiKnowledgeMiningBadge} alt="OpenHack: AI-Powered Knowledge Mining Badge" width="100px" height="100px" />
                        {/* <p>hidden</p> */}
                    </a>
                </section>

                <section className="badges second-row">
                    <a href="https://www.credly.com/badges/bc603fa3-3d2f-486f-8681-83abe267e41b" target="_blank" rel="noreferrer">
                        <img src={awsBadge} alt="AWS Badge" width="100px" height="100px" />
                        {/* <p>hidden</p> */}
                    </a>
                    <a href="https://openbadgepassport.com/app/badge/info/410046" target="_blank" rel="noreferrer">
                        <img src={hhBadge} alt="HH Badge" width="100px" height="100px" />
                        {/* <p>hidden</p> */}
                    </a>
                </section>

                {
                    achievements.length === 0
                    ? <Loader />
                    : <div className="edu-container">
                        {
                            achievements?.map((a, i) => (
                                <Achievement 
                                    key={i}
                                    title={a?.title}
                                    link={a?.link}
                                    header={a?.header}
                                    content={a?.content}
                                    author={a?.author}
                                />
                            ))
                        }
                    </div>
                }
            </article>
        </Container>
    )
}

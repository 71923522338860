import React from "react"

import Container from "../components/Container"
import ContactButton from "../components/ContactButton"

export default function Contact() {
    return (
        <Container>
            <article id="contact" className="wrapper">
                <h1>Contact</h1>

                <div>
                    <p>At the moment, I'm not looking for any new opportunity but I'm always 
                        looking forward to expand my network and 
                        making new friends.
                    </p>
                    <p>Feel free to contact me any time!</p>
                </div>
        
                <ContactButton />
            </article>
        </Container>
    )
}
